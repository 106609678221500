import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {

	static values =  {
		offset: Number,
		item: Number,
		date: String,
	}  // the turbo_frame element to render in

	connect() {
		AppContext.log("item-calendar-controller#connect")
		// if ( ! this.hasOffsetValue ) {
		// 	this.offsetValue = 0
		// }
		// this.element.querySelectorAll( 'td[data-date]' ).forEach( ( element ) => element.addEventListener( 'click', this.dayClick ) )
		this.connection_status = true
	}
	
	offsetValueChanged() {
		if ( this.connection_status ) {
			AppContext.log("item-calendar-controller#offsetValueChanged")
			// this.getPlacementsCalendar()
			this.element.src = `/items/${ this.itemValue }/placements/calendar.turbo_stream?offset=${this.offsetValue}`
		}
	}
	
	dateValueChanged() {
		if ( this.connection_status ) {
			AppContext.log("item-calendar-controller#dateValueChanged")
			this.element.src = `/items/${ this.itemValue }/placements/new.turbo_stream?start_date=${this.dateValue}`
			// this.element.src = `/items/${ this.itemValue }/placements/new.turbo_stream?start_date=${this.dateValue}&offset=${this.offsetValue}`
		}
	}
	
	day( event ) {
		// avoid loading calendars before conncet
		
		AppContext.log("item-calendar-controller#day")
		this.dateValue = event.target.dataset.date
		
	
		// if ( el.dataset.placementId || el.classList.contains('pre') ) {
		// 	$('#new_placement').remove();
		// 	markSelectedDates();
		// }
		// else {
		// 	if (el.classList.contains('not-ish')) {
		// 		$('#placement_date_range').toggleClass('is-invalid');
		// 		$('#new_placement input').attr('disabled', true);
		// 		markSelectedDates();
		// 	}
		// 	else {
		// 		$.getScript('/items/' + AppContext['item'] + '/placements/new?start_date=' + format_date( date ) ); // will call markSelectedDates() in response
		// 	}
		// }
	}
	getPlacementsCalendar() {
		if ( this.connection_status === true ) {
			let month_offset = this.offsetValue
			let date = this.element.querySelector( '[data-date]:not(.out)' ).dataset.date // first one will suffice
			if ( month_offset != 0 ) {
				date = new Date( date ).shiftMonths( month_offset ).toISODateString()
			}
			AppContext.log(`computed date: ${ date }`)
		}

		// $.getScript('/items/' + AppContext['item'] + '/placements/calendar.js?date=' + date
	}

	previous( event ){
		this.offsetValue -= 2
	}

	next( event ){
		this.offsetValue += 2
	}

	today( event ){
		this.offsetValue = 0
	}
	
	// get_id( descendant ){
	//   let el = descendant.closest('.card, .item-page')
	//   let dom_id = el.id.split('_').pop()
	// 	this.item_id = parseInt( dom_id )
	// 	AppContext['item'] = this.item_id
	// }
	// 
	// launch_editor( association, descendant ) {
	// 	this.get_id( descendant );
	// 	this.frameTarget.src = `/items/${ this.item_id }/${ association }.turbo_stream`
	// 	$('#item-modal').modal('show')
	// }

}

// 	$('#itemPlacementsModal').keydown(function(event) {
// 	  if ( AppContext['.placements-calendar'] ) {
// 			which = event.keyCode || event.which;
// 			if ( which == 37 ) { // 'left_arrow'
// 				event.stopPropagation();
// 				previous();
// 			}
// 			if ( which == 39 ) { // 'right_arrow'
// 				event.stopPropagation();
// 				next();
// 			}
// 			if ( which == 35 ) { // 'end'
// 				event.stopPropagation();
// 				today();
// 			}
// 			if ( which == 36 ) { // 'home'
// 				event.stopPropagation();
// 				today();
// 			}
// 			if ( which == 27 ) { // 'escape'
// 				AppContext['modal_escaped'] = true;
// 			}
// 		}
// 	});

// var get_placements_list = function() {
// 	url = '/items/' + AppContext['item'] + '/placements.js';
// 	$.getScript( url );
// 	AppContext.log(url);
// };
// 
// 
// var dayClick = function() {
// 	el = $(this);
// 	date = el.data('date');
// 	if ( el.data('placement-id') || el.classList.contains('pre') ) {
// 		$('#new_placement').remove();
// 		markSelectedDates();
// 	}
// 	else {
// 		if (el.classList.contains('not-ish')) {
// 			$('#placement_date_range').toggleClass('is-invalid');
// 			$('#new_placement input').attr('disabled', true);
// 			markSelectedDates();
// 		}
// 		else {
// 			$.getScript('/items/' + AppContext['item'] + '/placements/new?start_date=' + format_date( date ) ); // will call markSelectedDates() in response
// 		}
// 	}
// };
// 
// var markSelectedDates = function() {
// 	// will also clear previously selected dates
// 	start_date = new Date( $('#placement_start_date').val() );
// 	end_date = new Date( $('#placement_end_date').val() );
// 	$('.placements-calendar td[data-date]').each(function(){
// 		var day_cell = $(this);
// 		date = new Date( day_cell.data('date') );
// 		if (date >= start_date && date < end_date) {
// 			day_cell.addClass('selected');
// 		} else {
// 			day_cell.removeClass('selected');
// 		}
// 	});
// };
