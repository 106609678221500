// app/javascript/helpers/index.js

function dispatchEvent( element, type, eventInit = {} ) {
  const { disabled } = element
  const { bubbles, cancelable, detail } = eventInit
  const event = document.createEvent( "Event" )

  event.initEvent( type, bubbles || true, cancelable || true )
  event.detail = detail || {}

  try {
    element.disabled = false
    element.dispatchEvent( event )
  } finally {
    element.disabled = disabled
  }

  return event
}

function findElement( root, selector ) {
  if ( typeof root == "string" ) {
    selector = root;
    root = document;
  }
  return root.querySelector( selector );
}

function findElements( root, selector ) {
  if ( typeof root == "string" ) {
    selector = root
    root = document
  }
  const elements = root.querySelectorAll( selector )
  return toArray( elements )
}

function getMetaValue( name ) {
  const element = findElement( document.head, `meta[ name="${name}" ]` );
  if ( element ) {
    return element.getAttribute( "content" );
  }
}

function insertAfter( el, referenceNode ) {
  return referenceNode.parentNode.insertBefore( el, referenceNode.nextSibling );
}
function insertBefore( el, referenceNode ) {
  return referenceNode.parentNode.insertBefore( el, referenceNode );
}

function removeElement( el ) {
  if ( el && el.parentNode ) {
    el.parentNode.removeChild( el );
  }
}

function toArray( value ) {
  if ( Array.isArray( value ) ) {
    return value;
  } else if ( Array.from ) {
    return Array.from( value );
  } else {
    return [].slice.call( value );
  }
}

export { 
	dispatchEvent, 
	findElement, 
	findElements, 
	getMetaValue, 
	insertAfter, 
	insertBefore, 
	removeElement, 
	toArray 
}; // from 'helpers'