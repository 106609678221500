import { DirectUpload } from "./direct_upload"
export { DirectUpload }
AppContext.log("instantstorage/index.js")

// Don't load ujs -- handle using a Stimulus controller attached to the upload form instead

// import { start } from "./ujs"
// import { DirectUpload } from "./direct_upload"
// export { start, DirectUpload }

// function autostart() {
//   if (window.ActiveStorage) {
//     start()
//   }
// }
// 
// setTimeout(autostart, 1)
