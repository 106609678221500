import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {

	static targets = [ "frame" ] // the turbo_frame element to render in

	connect() {
		AppContext.log("images-controller#connect")
	}

	images( event ) {
		this.launch_editor( 'images', event.target )
	}
	
	placements( event ) {
		this.launch_editor( 'placements', event.target )
	}
	
	barcodes( event ) {
		this.launch_editor( 'barcodes', event.target )
		// this.get_id( event.target ); // will set AppContext['item']
		// this.frameTarget.src = `/items/${this.item_id}/edit_barcodes.turbo_stream`
		// $('#item-modal').modal('show')
	}
	
	get_id( descendant ){
		let el = descendant.closest('.card, .item-page')
		let dom_id = el.id.split('_').pop()
		this.item_id = parseInt( dom_id )
		AppContext['item'] = this.item_id
	}

	launch_editor( association, descendant ) {
		this.get_id( descendant );
		this.frameTarget.src = `/items/${ this.item_id }/${ association }.turbo_stream`
		// Turbo.visit( `/items/${ this.item_id }/${ association }.turbo_stream` )
		$('#item-modal').modal('show')
	}

}
