import ExifReader from 'exifreader'

// imageProcessor = new ImageProcessor( blob )
// imageProcessor.downsample( { width: 480, height: 320 } ).then( ( blob ) => {
//    // upload blob to server
// } )

const hasBlobConstructor = typeof ( Blob ) !== 'undefined' && ( function checkBlobConstructor() {
    try { return Boolean( new Blob() ); } catch ( error ) { return false; }
}() );
const hasArrayBufferViewSupport = hasBlobConstructor && typeof ( Uint8Array ) !== 'undefined' && ( function checkArrayBufferView() {
	try { return new Blob( [ new Uint8Array( 100 ) ] ).size === 100; } catch ( error ) { return false; }
}() );
const hasToBlobSupport = ( typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false );
const hasBlobSupport = ( hasToBlobSupport || ( typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined' ) );
const hasReaderSupport = ( typeof FileReader !== 'undefined' || typeof URL !== 'undefined' );
const hasCanvasSupport = ( typeof HTMLCanvasElement !== 'undefined' );

function reductionFactor( width, height, minWidth, minHeight ) {
	return Math.min( Math.floor( width / minWidth ), Math.floor( height / minHeight ) )
}

export class ImageProcessor {

	// TODO the whole process could be simlplified into an async static method if params were passed to methods rather than saving state in constructor

	constructor( file, minimumWidth, minimumHeight, quality ) { 
		this.blob = null // could be used as blob if we don't transform the image
		this.minimumWidth = minimumWidth || 720
		this.minimumHeight = minimumHeight || 720
		this.quality = quality || 1.0
		// this.name = file.name.split('.').slice(0, -1).join('.')
		console.log(file)
		// alert( file.name )
		this.browserSupport = hasCanvasSupport && hasBlobSupport && hasReaderSupport;
		this.ready = this.resize( file ) // this.init( file ) // will need to await ready
	}

	// init = async ( file ) => {
	// 	// await this.readImageFileTags()
	// 	// if ( this.orientation > 1 ) {
	// 	// 	// must correct orientation, regardless of width and height
	// 	// 	console.log( `must correct orientation, regardless of width and height. Orientation is: ${ this.orientation }` )
	// 	// }
	// 	// else {
	// 	// 	// without placing the image in an image element, we may have already obtained the 
	// 	// 	if ( this.width && this.height ) {
	// 	// 		this.factor = reductionFactor( this.width, this.height, this.minimumWidth, this.minimumHeight )
	// 	// 		if ( this.factor > 1 ) {
	// 	// 			// Need to downsample image
	// 	// 			console.log( `Need to downsample image, factor is ${ this.factor }` )
	// 	// 		}
	// 	// 		else {
	// 	// 			// No need to resample, file can serve as blob
	// 	// 			console.log( `No need to resample, file can serve as blob` )
	// 	// 			this.blob = file
	// 	// 			return
	// 	// 		}
	// 	// 	}
	// 	// 	else {
	// 	// 		// MAY need to downsample image, but we have no width and height to check
	// 	// 		console.log( `ExifReader did not obtain width/height/orientation` )
	// 	// 	}
	// 	// }
	// 	// // may have returned earlier choosing the original file, otherwise, resize
	// 	await this.resize( file )
	// 	return
	// }

	getBlob = async () => {
		await this.ready
		console.log( `this.blob.name: '${ this.blob.name }' this.blob.content_type: '${ this.blob.content_type }'` )
		return this.blob
	}
	// // canvas.toBlob( callback, mimeType, qualityArgument ); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
	// // As a blob
	// // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
	// const myPromise = new Promise((resolve, reject) => {
	//   setTimeout(() => {
	//     resolve('foo');
	//   }, 300);
	// });
	// return new Promise( ( resolve, reject ) => { // } )
	//   canvas.toBlob( blob => { // } )
	//     blob.name = fileName;
	//     resolve( blob );
	//   }, 'image/jpeg', 1 );
	// } );

  // readImageFileTags = ( file ) => {
  //   return new Promise( ( resolve, reject ) => {
  // 	    let reader = new FileReader();
  //     reader.onerror = () => {
  //       reader.abort();
  //       reject( new DOMException( "Problem parsing image file." ) );
  //     };
  //     reader.onload = () => {
  // 				try {
  // 					// the result attribute contains an ArrayBuffer representing the file's data.
  // 					let tags = ExifReader.load( reader.result );
  // 					// The MakerNote tag can be really large. Remove it to lower memory usage if you're parsing a lot of files and saving the tags.
  // 					delete tags[ 'MakerNote' ];
  // 					this.width = tags[ "Image Width"]?.value
  // 					this.height = tags[ "Image Height" ]?.value
  // 					this.orientation = tags[ "Orientation" ]?.value || 1
  // 					console.log( `Image info -- width: ${ this.width }, height: ${ this.height }, orientation: ${ this.orientation }` )
  // 					resolve()
  // 				} catch ( error ) {
  // 					// console.log(`ExifReader did not work -- ${error}`)
  // 					reject(`ExifReader did not work -- ${error}`)
  // 					// Handle error.
  // 				}
  //     };
  //     reader.readAsArrayBuffer( file ) // should trigger the onload method we are listening for
  //   });
  // };


	// // 	const reader = new FileReader()
	// // 	reader.onload = function ( readerEvent ) {
	// // 		try {
	// // 			// the result attribute contains an ArrayBuffer representing the file's data.
	// // 			const result = readerEvent.target.result;
	// // 			const tags = ExifReader.load( result );
	// // 			// The MakerNote tag can be really large. Remove it to lower memory usage if you're parsing a lot of files and saving the tags.
	// // 			delete tags[ 'MakerNote' ];
	// // 			const height = tags[ "Image Height" ]?.value, 
	// // 						width = tags[ "Image Width"]?.value, 
	// // 						orientation = tags[ "Orientation" ] || 1
	// // 			if ( width && height ) {
	// // 				let factor = reductionFactor( width, height, 750, 750 )
	// // 				if ( factor > 1 || orentation > 1 ) {
	// // 					// Need to resize or correct orientation
	// // 					// … use ImageTools
	// // 				}
	// // 			}
	// // 			else {
	// // 				console.log(`ExifReader did not obtain width/height`)
	// // 			}
	// // 			// Use the tags now present in `tags`.
	// // 		} catch ( error ) {
	// // 			console.log(`ExifReader did not work -- ${error}`)
	// // 			// Handle error.
	// // 		}
	// // 	}
	// // 	// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer
	// // 	// TODO: The Blob.arrayBuffer() method is a newer promise-based API to read a file as an array buffer. -- but caniuse has it around 88% vs. "Full support" for FileReader/readAsArrayBuffer
	// // 	// The FileReader interface's readAsArrayBuffer() method is used to start reading the contents of a specified Blob or File. 
	// // 	// … When the read operation is finished, the readyState becomes DONE, and the loadend is triggered. 
	// // 	// … At that time, the result attribute contains an ArrayBuffer representing the file's data.
	// // 	reader.readAsArrayBuffer( file ) // should trigger the onload method we are listening for

	resize = ( file ) => new Promise( ( resolve ) => {
		if ( !this.browserSupport || !file.type.match( /image.*/ ) || file.type.match( /image\/gif/ ) ) {
			console.log( "early exit - not supported" )
			this.blob = file
			resolve() // early exit - not supported
		}
		const image = document.createElement( 'img' );
		const url = URL.createObjectURL( file );
		image.onload = () => {
			// document.querySelector('[data-controller="item"]').insertAdjacentElement('afterbegin', image )
			URL.revokeObjectURL( url ); // loaded image, so revoke ObjectURL
			// if ( this.width != image.width ) {
			// 	console.log( `Exif width: ${ this.width } vs ImageElement width: ${ image.width }` )
			// }
			// if ( this.height != image.height ) {
			// 	console.log( `Exif height: ${ this.height } vs ImageElement height: ${ image.height }` )
			// }
			let width = image.width; // could use naturalWidth
			let height = image.height; // could use naturalHeight
			let factor = reductionFactor( width, height, this.minimumWidth, this.minimumHeight )
			if ( factor > 1 ) {
				width /= factor
				height /= factor
			}

			// const canvas = this.drawImageToCanvas( image, 0, 0, width / factor, height / factor );
			const canvas = this.drawImageToCanvas( image, 0, 0, width, height );
			const content_type = 'image/jpeg'
			this.toBlob( canvas, content_type, this.quality )
			.then( ( blob ) => {
				this.blob = blob
				this.blob.content_type = content_type;
				this.blob.name = `${ file.name.split('.').slice(0, -1).join('.') }.jpg`; // `${ this.name }_${ this.width }x${ this.height }.jpg`;
				resolve()
			} );
			// document.querySelector('[data-controller="item"]').insertAdjacentElement('afterbegin', canvas )
			console.log( `converting canvas to blob` )

			// // ( factor > 1 || this.orientation > 1 ) 
			// if ( true ) {
			// 	// const canvas = this.drawImageToCanvas( image, this.orientation, 0, 0, this.width / factor, this.height / factor, 'contain' );
			// 	const canvas = this.drawImageToCanvas( image, 0, 0, this.width / factor, this.height / factor );
			// 	const content_type = 'image/jpeg'
			// 	this.toBlob( canvas, content_type, this.quality )
			// 	.then( ( blob ) => {
			// 		this.blob = blob
			// 		this.blob.content_type = content_type;
			// 		this.blob.name = `${ this.name }.jpg`; // `${ this.name }_${ this.width }x${ this.height }.jpg`;
			// 		resolve()
			// 	} );
			// 	// document.querySelector('[data-controller="item"]').insertAdjacentElement('afterbegin', canvas )
			// 	console.log( `converting canvas to blob` )
			// }
			// else {
			// 	console.log( `resize: neither too large nor wrong orientation` )
			// 	resolve()
			// }
		}
		image.src = url;
	} );

	// resize = ( file, maxDimensions ) => new Promise( ( resolve ) => {
	// 	if ( !this.browserSupport || !file.type.match( /image.*/ ) ) {
	// 		return resolve( file );  // early exit - not supported
	// 	}
	// 	if ( file.type.match( /image\/gif/ ) ) {
	// 		return resolve( file ); // early exit - could be an animated gif
	// 	}
	// 	const image = document.createElement( 'img' );
	// 	image.onload = () => {
	// 		let width  = image.width;
	// 		let height = image.height;
	// 		if ( width >= height && width > maxDimensions.width ) {
	// 			height *= maxDimensions.width / width;
	// 			width = maxDimensions.width;
	// 		} 
	// 		else {
	// 			if ( height > maxDimensions.height ) {
	// 				width *= maxDimensions.height / height;
	// 				height = maxDimensions.height;
	// 			} 
	// 			else { 
	// 				return resolve( file ); // early exit; no need to resize
	// 			}
	// 		}
	// 		EXIF.getData( image, () => {
	// 			const orientation = EXIF.getTag( image, 'Orientation' ); // 0x0112 : "Orientation"
	// 			const canvas = this.drawImageToCanvas( image, orientation, 0, 0, width, height, 'contain' );
	// 			if ( hasToBlobSupport ) {
	// 				canvas.toBlob( blob => resolve( blob ), file.type );
	// 			}
	// 			else {
	// 				resolve( this.toBlob( canvas, file.type ) );
	// 			}
	// 		} );
	// 	};
	// 	this.loadImage( image, file );
	// 	return true;
	// } );

	// crop = ( file, dimensions ) => { 
	// 	new Promise( ( resolve ) => {
	// 		if ( !this.browserSupport || !file.type.match( /image.*/ ) ) {
	// 			return resolve( file ); // early exit - not supported
	// 		}
	// 		if ( file.type.match( /image\/gif/ ) ) {
	// 			return resolve( file ); // early exit - could be an animated gif 
	// 		}
	// 		const image = document.createElement( 'img' );
	// 		image.onload = () => {
	// 			if ( dimensions.width > image.width && dimensions.height > image.height ) {
	// 				return resolve( file ); // early exit - no need to resize 
	// 			}
	// 			const width = Math.min( dimensions.width, image.width );
	// 			const height = Math.min( dimensions.height, image.height );
	// 
	// 			if ( image.width > dimensions.width * 2 || image.height > dimensions.height * 2 ) {
	// 				return this.resize( file, { width: dimensions.width * 2, height: dimensions.height * 2 } ).then( ( zoomedOutImage ) => {
	// 					this.crop( zoomedOutImage, { width, height } ).then( resolve );
	// 				} );
	// 			}
	// 			EXIF.getData( image, () => {
	// 				const orientation = EXIF.getTag( image, 'Orientation' );
	// 				const canvas = this.drawImageToCanvas( image, orientation, 0, 0, width, height, 'crop' );
	// 				if ( hasToBlobSupport ) {
	// 					canvas.toBlob( blob => resolve( blob ), file.type );
	// 				}
	// 				else {
	// 					resolve( this.toBlob( canvas, file.type ) );
	// 				}
	// 			} );
	// 		};
	// 		this.loadImage( image, file );
	// 		return true;
	// 	} 
	// } );

	// drawImageToCanvas = ( img, orientation, x, y, width, height, method = 'contain' ) => {
	drawImageToCanvas = ( img, x, y, width, height ) => {
		const canvas = document.createElement( 'canvas' );
		const ctx = canvas.getContext( '2d' );

		// orientation = Number( orientation )
		canvas.width = width;
		canvas.height = height;
// SIMPLER
// 		// ctx.save();
// 		if ( orientation > 4 && orientation < 9 ) {
// 			canvas.width = height; // orientation is perpendicular
// 			canvas.height = width;
// 		}
// 		// switch ( orientation ) {
// 		// 	// explained here: https://i.stack.imgur.com/6cJTP.gif
// 		// 	case 1:
// 		// 	break;
// 		// 	case 2:
// 		// 	ctx.translate( width, 0 );
// 		// 	ctx.scale( -1, 1 );
// 		// 	break;
// 		// 	case 3:
// 		// 	ctx.translate( width, height );
// 		// 	ctx.rotate( ( 180 / 180 ) * Math.PI );
// 		// 	break;
// 		// 	case 4:
// 		// 	ctx.translate( 0, height );
// 		// 	ctx.scale( 1, -1 );
// 		// 	break;
// 		// 	case 5:
// 		// 	ctx.rotate( ( 90 / 180 ) * Math.PI );
// 		// 	ctx.scale( 1, -1 );
// 		// 	break;
// 		// 	case 6:
// 		// 	// ctx.rotate( ( 90 / 180 ) * Math.PI );
// 		// 	// ctx.translate( 0, -height );
// 		// 	break;
// 		// 	case 7:
// 		// 	ctx.rotate( ( 270 / 180 ) * Math.PI );
// 		// 	ctx.translate( -width, height );
// 		// 	ctx.scale( 1, -1 );
// 		// 	break;
// 		// 	case 8:
// 		// 	ctx.translate( 0, width );
// 		// 	ctx.rotate( ( 270 / 180 ) * Math.PI );
// 		// 	break;
// 		// 	default:
// 		// 	break;
// 		// }
// 
// 		if ( method === 'crop' ) {
// 			ctx.drawImage( img, ( img.width / 2 ) - ( width / 2 ), ( img.height / 2 ) - ( height / 2 ), width, height, 0, 0, width, height );
// 		} 
// 		else {
// 			// ctx.drawImage( img, x, y, width, height );
			ctx.drawImage( img, x, y, canvas.width, canvas.height );
// 		}
// 		// ctx.restore();

		return canvas;
	};

	toBlob = ( canvas, type, quality ) => new Promise( ( resolve ) => {
		if ( hasToBlobSupport ) {
			console.log( `ImageProcessor.toBlob using hasToBlobSupport=true type: ${ type } quality: ${ quality }` );
			canvas.toBlob( blob => {
				this.blob = blob; 
				resolve( blob );
			}, type, quality );
			// return new Promise( ( resolve) => {
			// 	canvas.toBlob( blob => {
			// 		this.blob = blob; 
			// 		resolve( blob );
			// 	}, type, quality );
			// });
			// canvas.toBlob( blob => {
			// 	this.blob = blob; 
			// 	return blob;
			// }, type, quality ); // file.type
		}
		else {
			const dataURI = canvas.toDataURL( type );
			const dataURIParts = dataURI.split( ',' );
			let byteString;
			if ( dataURIParts[0].indexOf( 'base64' ) >= 0 ) {
				byteString = atob( dataURIParts[ 1 ] );
			} else {
				byteString = decodeURIComponent( dataURIParts[ 1 ] );
			}
			const arrayBuffer = new ArrayBuffer( byteString.length );
			const intArray = new Uint8Array( arrayBuffer );

			for ( let i = 0; i < byteString.length; i += 1 ) {
				intArray[i] = byteString.charCodeAt( i );
			}

			const mimeString = dataURIParts[0].split( ':' )[ 1 ].split( ';' )[ 0 ];
			let blob = null;

			if ( hasBlobConstructor ) {
				blob = new Blob( [ hasArrayBufferViewSupport ? intArray : arrayBuffer ], { type: mimeString } );
			} 
			else {
				const bb = new BlobBuilder();
				bb.append( arrayBuffer );
				blob = bb.getBlob( mimeString );
			}

			this.blob = blob;
			resolve( blob );
		}
	} );

	// // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
	// // canvas.toBlob( function( blob ){...}, 'image/jpeg', 0.95 ); // to get the image in JPEG format at 95% quality
	// var canvas = document.getElementById( 'canvas' );
	// 
	// canvas.toBlob( function( blob ) {
	//   var newImg = document.createElement( 'img' ),
	//       url = URL.createObjectURL( blob );
	// 
	//   newImg.onload = function() {
	//     // no longer need to read the blob so it's revoked
	//     URL.revokeObjectURL( url );
	//   };
	// 
	//   newImg.src = url;
	//   document.body.appendChild( newImg );
	// } );

	// // https://developer.mozilla.org/en-US/docs/Web/API/File/File
	// // new File( bits, name[, options] );
	// // Parameters
	// // 
	// // bits -- An Array of ArrayBuffer, ArrayBufferView, Blob, USVString objects, or a mix of any of such objects, that will be put inside the File. USVString objects are encoded as UTF-8.
	// // name -- A USVString representing the file name or the path to the file.
	// // options -- An options object containing optional attributes for the file. Available options are as follows:
	// // type: A DOMString representing the MIME type of the content that will be put into the file. Defaults to a value of "".
	// // lastModified: A number representing the number of milliseconds between the Unix time epoch and when the file was last modified. Defaults to a value of Date.now().

	loadImage = ( image, file ) => {
		// HTMLImageElement.naturalHeight
		console.log( `loadImage( image, file:${ file.name } )` )
		if ( typeof ( URL ) === 'undefined' ) {
			console.log( `( typeof ( URL ) === 'undefined' )` )
			const reader = new FileReader();
			reader.onload = ( event ) => {
				image.src = event.target.result;
			};
			// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
			// will read as a base64 encoded string
			reader.readAsDataURL( file );
		} else {
			// https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
			// creates a DOMString containing a URL representing the object ( the specified File object or Blob object ) given in the parameter. 
			// The URL lifetime is tied to the document in the window on which it was created.
			console.log( `URL.createObjectURL( file:${ file.name } )` )
			this.url = URL.createObjectURL( file );
			image.src = this.url;
		}
	};

}



// <!DOCTYPE HTML>
// <html>
// <head>
// <meta charset="utf-8">
// </head><body>
//  
// <?php
//  
// if ( count( $_POST ) && ( strpos( $_POST['img'], 'data:image/png;base64' ) === 0 ) ) {
//      
//   $img = $_POST['img'];
//   $img = str_replace( 'data:image/png;base64,', '', $img );
//   $img = str_replace( ' ', '+', $img );
//   $data = base64_decode( $img );
//   $file = 'uploads/img'.date( "YmdHis" ).'.png';
//    
//   if ( file_put_contents( $file, $data ) ) {
//      echo "<p>The canvas was saved as $file.</p>";
//   } else {
//      echo "<p>The canvas could not be saved.</p>";
//   } 
//    
// }
//                       
// ?>
//  
// <canvas id="canv" width="200" height="200"></canvas>
//  
// <form method="post" action="" onsubmit="prepareImg();">
//   <input id="inp_img" name="img" type="hidden" value="">
//   <input id="bt_upload" type="submit" value="Upload">
// </form>
//  
//  
//  
// <script>
//      
//   var canvas = document.getElementById( 'canv' );
//   var context = canvas.getContext( '2d' );
//  
//   context.arc( 100, 100, 50, 0, 2 * Math.PI );
//   context.lineWidth = 5;
//   context.fillStyle = '#EE1111';
//   context.fill();   
//   context.strokeStyle = '#CC0000';
//   context.stroke();
//      
//      
//   function prepareImg() {
//      var canvas = document.getElementById( 'canv' );
//      document.getElementById( 'inp_img' ).value = canvas.toDataURL();
//   }
//    
// </script>
//  
// </body></html>
