import { Controller } from "stimulus"

import { DirectUpload } from "instantstorage"

import { toArray } from "helpers"

import { ImageProcessor } from "../helpers/image_processor.js"

// import ExifReader from 'exifreader'

const processingAttribute = "data-direct-uploads-processing"

// OLD exif-js: https://github.com/exif-js/exif-js
// exifreader: https://github.com/mattiasw/ExifReader

// instant_attach_controller
// Stimulus Controller version 
// Acts as a replacement for ActiveStorage/UJS and ActiveStorage/DirectUploadsController
// Encapsulate the file input change event processing and allow 
// … converts image file references, uploads them to an activestorage blob 
// … and, using InstantAttachment, inserts a hidden form element with the same name as the file input with the blob's signed_id as a value
export default class extends Controller { 

	// TODO: move pixel dimension minimums and limits into configuration values or a server query
	// const minimumPixels = 750;

 	static targets = [ "output" ]

	connect() {
		// HERE
		AppContext.log( "instant-attachment-controller#connect" )
	}

	processFiles( callback ) {
		this.processing = true
		this.input.disabled = true
		this.input.parentElement.classList.add('hidden')

		// this.files = [] // empty the list, only to add the file/blob we choose
		const files = toArray( this.input.files ) // || []
		// this.files may or may not be the original files from the file input element
		// … we may have scaled down some images
		// by starting controllers sequntially, we can stop on first error, which will prevent form submission
		// NOTE: this may create some orphaned blobs with no associated item image attachments
    const startNextFile = () => {
      const file = files.shift()
      if ( file ) {
        this.processFile( file, error => {
          if ( error ) {
            callback( error )
          } else {
						// TODO consider creating the uploader for current file, only starting next if previous completed without error
						// this.files.push( file )
            startNextFile()
          }
        } )
      } else {
        // done, no files left, recursion complete
				callback()
      }
    }
    startNextFile() // recursively
		
	}

	processFile( file, callback ) {
		// constructor( file, minimumWidth, minimumHeight, quality )
		let imageProcessor = new ImageProcessor( file, 480, 420, 0.7 ) // this.element for testing
		// imageProcessor.resize( file, { width: 750, height: 750 }).then( ( blob ) => {
		imageProcessor.getBlob().then( ( blob ) => {
		   // upload blob to server
				console.log( `processed blob(name): '${ blob.name }' blob(content_type): '${ blob.content_type }'` )
				const controller = new InstantAttachment( this.element, this.input, blob )
		    controller.start( callback )
		})
		
		// const namedBlob = ImageProcessor( file, 750, ( error ) => {
		// 	if ( error ) {
		// 		callback( error )
		// 	}
		// 	else {
		// 		const controller = new InstantAttachment( this.element, this.input, namedBlob )
		//     controller.start( callback )
		// 	}
		// } )
	}

	upload( e ) {
		const form = this.element
		this.input = e.target
		// FROM activestorage/ujs
		event.preventDefault()
		if ( this.processing ) {
			return
		}

		this.processFiles( error => {
			if ( error ) {
				AppContext.log( `An error prevented an image from being processed: ${ error }` )
        alert( error )
			}
			else {
				// done, no errors
				this.submitForm()
			}
		} )
		
		AppContext.log("instant-attachment-controller#upload")
	};

	// function submitForm(form) // FROM activestorage/ujs -- instead the controller is attched to the form and so need not be passed to the function 
	submitForm() {
		const form = this.element // Assumes this controller was attached to the image upload form
		let button = form.querySelector( "input[type=submit], button[type=submit]" )
	  if ( button ) {
	    const { disabled } = button
	    button.disabled = false
	    button.focus()
	    button.click()
	    button.disabled = disabled
	  } else {
	    button = document.createElement( "input" )
	    button.type = "submit"
	    button.style.display = "none"
	    form.appendChild( button )
	    button.click()
	    form.removeChild( button )
	  }
	}

}

function reductionFactor( width, height, minWidth, minHeight ) {
	return Math.min( Math.floor( width / minWidth ), Math.floor( height / minHeight ) )
}

// class ImageProcessor {
// 	// USAGE: 
// 	// ImageProcessor.resize(file, { width: 480, height: 320 }).then((blob) => {
// 	//    // upload blob to server
// 	// })
// 	constructor() {
// 		// set up polyfills
// 	}
// 	static resize( file, options ) {
// 		return new Promise( ( resolve ) => {
// 			return resolve( file )
// 		} )
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 		// TODO: read image, correct orientation if necessary, and resize if necessary, returning either the original file or a new (named) blob for the processed image
// 	}
// }


// switch ( orientation ) {
// 	// explained here: https://i.stack.imgur.com/6cJTP.gif
// 	case 1:
// 	break;
// 	case 2:
// 	ctx.translate( width, 0 );
// 	ctx.scale( -1, 1 );
// 	break;
// 	case 3:
// 	ctx.translate( width, height );
// 	ctx.rotate( ( 180 / 180 ) * Math.PI );
// 	break;
// 	case 4:
// 	ctx.translate( 0, height );
// 	ctx.scale( 1, -1 );
// 	break;
// 	case 5:
// 	ctx.rotate( ( 90 / 180 ) * Math.PI );
// 	ctx.scale( 1, -1 );
// 	break;
// 	case 6:
// 	ctx.rotate( ( 90 / 180 ) * Math.PI );
// 	ctx.translate( 0, -height );
// 	break;
// 	case 7:
// 	ctx.rotate( ( 270 / 180 ) * Math.PI );
// 	ctx.translate( -width, height );
// 	ctx.scale( 1, -1 );
// 	break;
// 	case 8:
// 	ctx.translate( 0, width );
// 	ctx.rotate( ( 270 / 180 ) * Math.PI );
// 	break;
// 	default:
// 	break;
// }
// transform( ctx, width, height, orientation ) {
// 	// mask is EXIF orientation - 1 (results in a value from 0 to 7, to use as a bitmask)
// 	const mask = orientation - 1
// 	var scale_x = 1, 					// scale_x
// 			scale_y = 1,					// scale_y
// 			transform_w = width, 			// transform width
// 			transform_h = height,			// transform height
// 			transform_x_toggle = 0,					// transform width toggle
// 			transform_y_toggle = 0					// transform height toggle
// 	if ( mask & 4 ) {
// 		
// 		ctx.rotate( Math.PI / 2 );
// 		scale_x *= -1
// 		transform_w = height
// 		transform_h = width
// 	}
// 	if ( mask & 2 ) {
// 		scale_x *= -1
// 		scale_y *= -1
// 		transform_x_toggle = ( transform_x_toggle + 1 ) % 2
// 		transform_y_toggle = ( transform_y_toggle + 1 ) % 2
// 	}
// 	if ( mask & 1 ) {
// 		scale_x *= -1
// 		transform_x_toggle = ( transform_x_toggle + 1 ) % 2
// 	}
// 	if ( !( ( scale_x + 1 ) | ( scale_y + 1 ) ) ) { ctx.scale( scale_x, scale_y ) }
// 	if ( !!( transform_x_toggle | transform_y_toggle ) ) { ctx.translate( w, h ) }
// }


// // 	const reader = new FileReader()
// // 	reader.onload = function ( readerEvent ) {
// // 		try {
// // 			// the result attribute contains an ArrayBuffer representing the file's data.
// // 			const result = readerEvent.target.result;
// // 			const tags = ExifReader.load( result );
// // 			// The MakerNote tag can be really large. Remove it to lower memory usage if you're parsing a lot of files and saving the tags.
// // 			delete tags[ 'MakerNote' ];
// // 			const height = tags[ "Image Height" ]?.value, 
// // 						width = tags[ "Image Width"]?.value, 
// // 						orientation = tags[ "Orientation" ] || 1
// // 			if ( width && height ) {
// // 				let factor = reductionFactor( width, height, 750, 750 )
// // 				if ( factor > 1 || orentation > 1 ) {
// // 					// Need to resize or correct orientation
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 					// … use ImageTools
// // 				}
// // 			}
// // 			else {
// // 				console.log(`ExifReader did not obtain width/height`)
// // 			}
// // 			// Use the tags now present in `tags`.
// // 		} catch ( error ) {
// // 			console.log(`ExifReader did not work -- ${error}`)
// // 			// Handle error.
// // 		}
// // 	}
// // 	// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer
// // 	// TODO: The Blob.arrayBuffer() method is a newer promise-based API to read a file as an array buffer. -- but caniuse has it around 88% vs. "Full support" for FileReader/readAsArrayBuffer
// // 	// The FileReader interface's readAsArrayBuffer() method is used to start reading the contents of a specified Blob or File. 
// // 	// … When the read operation is finished, the readyState becomes DONE, and the loadend is triggered. 
// // 	// … At that time, the result attribute contains an ArrayBuffer representing the file's data.
// // 	reader.readAsArrayBuffer( file ) // should trigger the onload method we are listening for

// TODO: choose whether to use file or create a blob to correct orientation and scale
// TODO: choose whether to use file or create a blob to correct orientation and scale
// TODO: choose whether to use file or create a blob to correct orientation and scale
// TODO: choose whether to use file or create a blob to correct orientation and scale
// TODO: choose whether to use file or create a blob to correct orientation and scale


// Replacement for ActiveStorage/DirectUploadController
class InstantAttachment {
  constructor( form, input, file ) {
		this.form = form
    this.input = input
    this.file = file
    this.directUpload = new DirectUpload( this.file, this.url, this )
		this.id = this.directUpload.id
		this._good = true
		// console.log("direct-upload:initialize")
		// console.log(`direct-upload:initialize EVENT ${ this.id } - <${ file.name }>`)
		if ( file && file.size > 0 ) {
			this.form.insertAdjacentHTML("beforeend", `
			<div id="direct-upload-${ this.id }" class="direct-upload direct-upload--pending">
				<div id="direct-upload-progress-${ this.id }" class="direct-upload__progress" style="width: 0%"></div>
				<span class="direct-upload__filename">${ file.name }</span>
			</div>
			`)
		}
		else {
			this._good = false
			this.form.insertAdjacentHTML("beforeend", `
			<div id="direct-upload-${ this.id }" class="direct-upload direct-upload--pending direct-upload--error" title="Empty File!">
				<div id="direct-upload-progress-${ this.id }" class="direct-upload__progress" style="width: 0%"></div>
				<span class="direct-upload__filename">${ file.name }</span>
			</div>
			`)
		}
  }

	get good() { return this._good }

  start( callback ) {
		if ( this._good ) {
	    const hiddenInput = document.createElement( "input" )
	    hiddenInput.type = "hidden"
	    hiddenInput.name = this.input.name
	    this.input.insertAdjacentElement( "beforebegin", hiddenInput )

			const element = document.getElementById( `direct-upload-${ this.id }` )
			element.classList.remove( "direct-upload--pending" )

	    this.directUpload.create( ( error, attributes ) => {
	      if ( error ) {
	        hiddenInput.parentNode.removeChild( hiddenInput )
					this._good = false
					element.classList.add( "direct-upload--error" )
					element.setAttribute( "title", error )
	      } else {
	        hiddenInput.value = attributes.signed_id
	      }
				element.classList.add( "direct-upload--complete" )
	      callback( error )
	    } )
		}
  }

  uploadRequestDidProgress( event ) {
    const progress = event.loaded / event.total * 100
    if ( progress ) {
			const progressElement = document.getElementById( `direct-upload-progress-${ this.id }` )
			progressElement.style.width = `${ progress }%`
    }
  }

  get url() {
    return this.input.getAttribute( "data-direct-upload-url" )
  }

  // DirectUpload delegates
  directUploadWillCreateBlobWithXHR( xhr ) {}
  directUploadWillStoreFileWithXHR( xhr ) {
    xhr.upload.addEventListener( "progress", event => this.uploadRequestDidProgress( event ) )
  }
}
