import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "sidebar" ]

	connect() {
		AppContext.log("menu-controller#connect")
	}

	toggle( e ) {
		this.element.classList.toggle('active')
	};
	
	
}
