import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "query", "item" ]

	connect() {
		AppContext.log("filter-controller#connect")
	// if ( this.hasQueryTarget ) {
	// 	this.registerKeyboardEventListeners()
	// }
	}

	apply( e ) {
		let query = this.queryTarget.value.toLowerCase().split( /\s+/ ).filter( token => token )
		this.itemTargets.forEach( element => {
			let text = element.innerText.toLowerCase()
			let match = true
			query.forEach( q => match && ( match = text.indexOf( q ) >= 0 ) )
			element.classList.toggle( 'hidden', !match )
		})
	}
}
